import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import JumboPhoto from "../../../components/jumbophoto"

export default () => <Layout>
	<Helmet title="About Chapter 11 Bankruptcy | Gina Mcdonald &amp; Associates, L.L.C." defer={false} />

	<JumboPhoto/>

	<Container>
		<Row>
			<Col>
				<h1>Chapter 11 <small>For Individual Consumer Debtors</small></h1>
				<p>Chapter 11 is part of the United States Bankruptcy Code. Chapter 11 generally gets in the news when major corporations -- like General Motors, K-Mart, and United Airlines -- have financial problems and turn to the bankruptcy courts for help. Most Chapter 11 cases, however, are filed by businesses and companies that are far from being household names. Although normally used for businesses, Chapter 11 can be used by the individual consumer debtor who exceeds the debt threshold allowed for Chapter 13.</p>

				<p>Currently, an individual cannot file Chapter 13 if he or she owes more than $383,175 in unsecured debt or $1,149,525 in secured debt.</p>

				<p>Under Chapter 11, an individual debtor can restructure personal finances through a plan of reorganization approved by the bankruptcy court. By reducing obligations and modifying payment terms, a Chapter 11 plan can help a debtor balance its income and expenses, if you exceed the limits to file Chapter 13 bankruptcy. Please contact our office to discuss your options at 205-982-3325.</p>
			</Col>
		</Row>
	</Container>
</Layout>